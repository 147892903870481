var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"app-container app-flex-col-container basePicListPage"},[_vm._t("top",null,{"sup_this":_vm.sup_this}),(_vm.isSearch)?_c('EHeader',{attrs:{"designedStyle":_vm.designedStyle,"sup_this":_vm.sup_this,"query":_vm.query,"formField":_vm.formField},on:{"searchChange":_vm.clearAllSelectedData}}):_vm._e(),_vm._t("batchOperation",[_c('div',{staticStyle:{"min-height":"30px"}})],{"sup_this":_vm.sup_this}),(_vm.hasCheckAllData4)?_c('CheckAllData4',{ref:"checkAllData4",attrs:{"isPageChange":_vm.isPageChange,"tableData":_vm.data,"page":_vm.page,"size":_vm.size,"total":_vm.total,"getAllDataApi":_vm.getAllCheckedSelectedDataApi,"selectionData":_vm.selectedData},on:{"update:isPageChange":function($event){_vm.isPageChange=$event},"update:is-page-change":function($event){_vm.isPageChange=$event},"update:selectionData":function($event){_vm.selectedData=$event},"update:selection-data":function($event){_vm.selectedData=$event}}}):_vm._e(),(_vm.needTopPaginationBar)?_c('PaginationBar',{attrs:{"page":_vm.page,"size":_vm.size,"total":_vm.total},on:{"refreshTableEventFun":_vm.refreshTableEventFun}}):_vm._e(),_c('div',{staticClass:"flex-col-content"},[_c('CommonTable',{ref:"table",attrs:{"height":"100%","tableLoading":_vm.tableLoading,"cell-style":_vm.changeCellStyle,"cols":_vm.cols,"infoData":_vm.data},on:{"selection-change":_vm.handleSelectionChange,"sort-change":_vm.handleSortChange},scopedSlots:_vm._u([{key:"originalMergeSlot",fn:function(ref){
var scoped = ref.scoped;
return [_c('defaultImg',{attrs:{"content":scoped.chineseName,"carouselList":_vm.originalImageList(scoped)}})]}},{key:"tortSlot",fn:function(ref){
var scoped = ref.scoped;
return [_vm._v(" "+_vm._s(_vm.getTortStatus(scoped))+" ")]}},{key:"picMergeSlot",fn:function(ref){
var scoped = ref.scoped;
return [_c('div',{staticClass:"merge-wrapper"},[_c('el-popover',{attrs:{"placement":"right","trigger":"hover","visible-arrow":false}},[_c('div',{attrs:{"slot":"reference"},slot:"reference"},[_c('defaultImg',{attrs:{"src":_vm.aLiCompressPicByUrl(scoped.thumbnailPath, 80)}})],1),_c('div',{staticStyle:{"height":"40px","line-height":"32px","background":"#fff"}},[_vm._v(" "+_vm._s(scoped.title)+" ")]),_c('defaultImg',{attrs:{"src":_vm.aLiCompressPicByUrl(scoped.thumbnailPath, 450)}})],1)],1)]}},{key:"mergeSlot",fn:function(ref){
var scoped = ref.scoped;
return [_c('div',{staticClass:"merge-wrapper"},[_c('defaultImg',{attrs:{"src":_vm.aLiCompressPicByUrl(scoped.thumbnailPath || scoped.path, 100),"content":scoped.title}}),_c('div',{staticClass:"name"},[_vm._v(" "+_vm._s(scoped.title)+" ")])],1)]}},{key:"styleMergeSlot",fn:function(ref){
var scoped = ref.scoped;
return _vm._l((_vm.handleStyle(scoped)),function(item,index){return _c('el-tag',{key:index,staticStyle:{"margin-right":"5px","margin-bottom":"5px","cursor":"pointer"},attrs:{"type":"primary"},on:{"click":function($event){_vm.changeData(scoped)
            scoped.pIndex = index}}},[_vm._v(" "+_vm._s(item)+" ")])})}},{key:"showPictureSlot",fn:function(ref){
            var scoped = ref.scoped;
return [_c('defaultImg',{attrs:{"lazy":"","scroll-container":"elTable","carouselList":_vm.originalPicture(scoped)}})]}},{key:"basicMergeSlot",fn:function(ref){
            var scoped = ref.scoped;
return [_vm._v(" "+_vm._s(_vm.handleState(scoped))+" ")]}},{key:"timeMergeSlot",fn:function(ref){
            var scoped = ref.scoped;
return [_c('span',[_vm._v(_vm._s(_vm._f("formatTime")(scoped[scoped.prop])))])]}},{key:"picSizeMergeSlot",fn:function(ref){
            var scoped = ref.scoped;
return [_c('color-text-btn',{attrs:{"type":"default"}},[_vm._v(" "+_vm._s(scoped.width)+" * "+_vm._s(scoped.height))])]}},{key:"designedMergeSlot",fn:function(ref){
            var scoped = ref.scoped;
return [_c('span',{staticStyle:{"display":"inline-block","width":"80px"},on:{"mouseenter":_vm.onMouseEnter,"mouseleave":_vm.onMouseLeave}},[_vm._v(_vm._s(scoped.customProductCount)+" "),(_vm.show && scoped.customProductCount !== 0)?_c('span',{staticStyle:{"color":"#3841db","cursor":"pointer","margin-left":"10px"},on:{"click":function($event){return _vm.onWatch(scoped)}}},[_vm._v("查看")]):_vm._e()])]}},{key:"stateMergeSlot",fn:function(ref){
            var scoped = ref.scoped;
return [(scoped.disable === 1)?_c('span',[_vm._v("禁用")]):_c('span',[_vm._v("启用")])]}},{key:"createdUserSlot",fn:function(ref){
            var scoped = ref.scoped;
return [(scoped.main_user)?_c('span',[_vm._v(_vm._s(((scoped.main_user) + "-" + (scoped.created_user_name))))]):_c('span',[_vm._v(_vm._s(scoped.created_user_name || '暂无'))])]}},{key:"createByNameSlot",fn:function(ref){
            var scoped = ref.scoped;
return [(scoped.mainUser)?_c('span',[_vm._v(_vm._s(((scoped.mainUser) + "-" + (scoped.createByName))))]):_c('span',[_vm._v(_vm._s(scoped.createByName || '暂无'))])]}},{key:"nameSlot",fn:function(ref){
            var scoped = ref.scoped;
return [(scoped.mainUser)?_c('span',[_vm._v(_vm._s(scoped.mainUser))]):_c('span',[_vm._v(_vm._s(scoped.createByName || '暂无'))])]}},{key:"disableSlot",fn:function(ref){
            var scoped = ref.scoped;
return [(scoped.disable === 1)?_c('span',[_vm._v("下架")]):_c('span',[_vm._v("上架")])]}},{key:"sizeMergeSlot",fn:function(ref){
            var scoped = ref.scoped;
return [_c('color-text-btn',{attrs:{"type":"default"}},[_vm._v(" "+_vm._s(_vm.suffix(scoped))+" ")])]}},{key:"categoryNameSlot",fn:function(ref){
            var scoped = ref.scoped;
return [_vm._v(" "+_vm._s(scoped.categoryName || '暂无')+" ")]}},{key:"createTimeSlot",fn:function(ref){
            var scoped = ref.scoped;
return [_c('span',[_vm._v(_vm._s(_vm.parseTime(scoped[scoped.prop]) || '暂无'))])]}}])},[_vm._t("default",null,{"sup_this":_vm.sup_this})],2)],1),(_vm.open)?_c('designedDialog',{attrs:{"visible":_vm.open,"product":_vm.productData},on:{"update:visible":function($event){_vm.open=$event}}}):_vm._e(),(_vm.display)?_c('orderDialog',{attrs:{"visible":_vm.display,"order":_vm.orderData},on:{"update:visible":function($event){_vm.display=$event}}}):_vm._e(),_c('PaginationBar',{attrs:{"page":_vm.page,"size":_vm.size,"total":_vm.total},on:{"refreshTableEventFun":_vm.refreshTableEventFun}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }