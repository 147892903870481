export const bulkDisableCols = [
  {
    prop: 'merge',
    label: '图片',
    minWidth: '150',
    slotName: 'mergeSlot'
  },
  {
    prop: 'categoryName',
    label: '图片分类'
  }
]
