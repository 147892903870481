export const publicGalleryField = [
  {
    label: '图片分类',
    prop: 'categoryId',
    name: 'categoryId',
    colNum: 4,
    slotName: 'categorySlot',
    placeholder: '请选择图片分类'
  },

  {
    label: '图片名称',
    prop: 'title',
    name: 'title',
    colNum: 4,
    placeholder: '请输入图片名称'
  },
  {
    label: '状态',
    prop: 'disable',
    name: 'disable',
    colNum: 4,
    slotName: 'shelvesSlot',
    placeholder: '请选择状态'
  },
  {
    label: '上传日期',
    prop: 'createTime',
    name: 'createTime',
    placeholder: '请选择上传日期',
    colNum: 9,
    slotName: 'timeSlot'
  },
  {
    label: '是否标记侵权',
    prop: 'isTort',
    name: 'isTort',
    placeholder: '请选择',
    colNum: 4,
    slotName: 'tortSlot'
  },
  {
    label: '',
    prop: 'done',
    name: 'done',
    colNum: 1,
    slotName: 'doneSlot'
  }
]
