import request from '@/service/request'
import { getXMenuType } from '@/utils/constant'

export function detail(data, menuType = 'btn') {
  return request({
    headers: getXMenuType(menuType),
    url: 'externaladmin/productService/productPrototypeCollection/productCustomGetById',
    method: 'post',
    data
  })
}

export function getDetail(data, menuType = 'other') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/productService/productPrototypeCollection/productCustomList',
    method: 'post',
    data
  })
}
