var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dialog-warpper"},[_c('el-dialog',{attrs:{"title":"查看","visible":_vm.visible,"top":"10vh","width":"1040px","before-close":_vm.handleClose},on:{"update:visible":function($event){_vm.visible=$event}}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"content-warpper"},[_c('el-popover',{attrs:{"placement":"right","trigger":"hover","visible-arrow":false}},[_c('div',{attrs:{"slot":"reference"},slot:"reference"},[_c('el-image',{staticStyle:{"width":"100px","height":"100px"},attrs:{"src":_vm.product.thumbnailPath,"fit":"contain"}})],1),_c('div',{staticStyle:{"height":"40px","line-height":"32px","background":"#fff"}},[_vm._v(" "+_vm._s(_vm.product.title)+" ")]),_c('el-image',{staticStyle:{"width":"450px","height":"450px"},attrs:{"fit":"contain","src":_vm.product.thumbnailPath,"z-index":9999}})],1),_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.product.title))])],1),_c('CommonTable',{staticStyle:{"padding":"0 24px"},attrs:{"selection":false,"tableLoading":_vm.tableLoading,"cols":_vm.cols,"height":"auto","infoData":_vm.data},scopedSlots:_vm._u([{key:"chineseNameSlot",fn:function(ref){
var scoped = ref.scoped;
return [_vm._v(" "+_vm._s(_vm.handleCnName(scoped))+" ")]}},{key:"englishNameSlot",fn:function(ref){
var scoped = ref.scoped;
return [_vm._v(" "+_vm._s(_vm.handleEnName(scoped))+" ")]}},{key:"styleSlot",fn:function(ref){
var scoped = ref.scoped;
return _vm._l((_vm.handleStyle(scoped)),function(item,index){return _c('el-tag',{key:index,staticStyle:{"margin-right":"5px"},attrs:{"type":"primary"}},[_vm._v(" "+_vm._s(item)+" ")])})}},{key:"sizeSlot",fn:function(ref){
var scoped = ref.scoped;
return _vm._l((_vm.handleSize(scoped)),function(item,index){return _c('el-tag',{key:index,staticStyle:{"margin-right":"5px"},attrs:{"type":"primary"}},[_vm._v(" "+_vm._s(item)+" ")])})}},{key:"pictureSlot",fn:function(ref){
var scoped = ref.scoped;
return [_c('el-popover',{attrs:{"placement":"right","trigger":"hover","visible-arrow":false}},[_c('div',{staticClass:"carousel-wrapper",attrs:{"slot":"reference"},slot:"reference"},[_c('el-carousel',{staticStyle:{"width":"150px"},attrs:{"indicator-position":"none","arrow":"always","autoplay":false,"loop":false,"height":"50px"},on:{"change":function($event){return _vm.slideChangeHandler(scoped.index, $event)}}},_vm._l((_vm.imagesList(scoped)),function(item,index){return _c('el-carousel-item',{key:index,staticClass:"swiper-item"},[_c('div',{staticClass:"img-wrapper"},[_c('el-image',{attrs:{"fit":"contain","src":item.showImagePath}})],1)])}),1),_c('div',{staticClass:"name-list"},_vm._l((_vm.handleStyle(scoped)),function(item,index){return _c('span',{key:index,class:{ active: scoped.currentStyleIndex === index },staticStyle:{"margin-right":"5px","cursor":"pointer"},on:{"click":function($event){return _vm.handleClicked(scoped.index, index)}}},[_vm._v(" "+_vm._s(item))])}),0)],1),_c('div',{staticStyle:{"height":"40px","line-height":"32px","background":"#fff"}},[_vm._v(" "+_vm._s(_vm.product.title)+" ")]),_c('div',{staticClass:"img-wrapper"},[_c('el-image',{staticStyle:{"width":"450px"},attrs:{"fit":"contain","src":_vm.coverPath(scoped)}})],1)])]}}])}),_c('PaginationBar',{ref:"paginationBar",attrs:{"total":_vm.total},on:{"refreshTableEventFun":_vm.refreshTableEventFun}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }