<template>
  <BaseDialog class="dialog-warpper" :dialogVisible="visible" title="侵权设置" width="750px" @closeHandle="handleClose">
    <span class="mr10">是否标记为侵权：</span>
    <el-radio v-model="radio" :label="0">否</el-radio>
    <el-radio v-model="radio" :label="1">是</el-radio>
    <template #footer>
      <loadingBtn type="primary" @click="onConfirm"> 确定 </loadingBtn>
      <el-button @click="handleClose"> 取消 </el-button>
    </template>
  </BaseDialog>
</template>

<script>
import { pictureSign } from '@/api/finishedProductManageApi'

export default {
  props: {
    visible: Boolean,
    selectedData: Array,
    sup_this: Object
  },

  data() {
    return {
      radio: 0
    }
  },

  computed: {
    getPictureList({ selectedData }) {
      return selectedData.map(({ title }) => title).join('、')
    }
  },

  methods: {
    handleClose() {
      this.$emit('update:visible', false)
    },

    async onConfirm() {
      const res = await awaitResolve(
        pictureSign({
          imageIdList: this.selectedData.map(({ id }) => id),
          isTort: this.radio
        })
      )
      if (!res) return
      this.handleClose()
      this.$emit('confirmHandler')
    }
  }
}
</script>

<style lang="scss" scoped></style>
